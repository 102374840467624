.container { 
    display: flex;
}

.middle { 
    font-weight: 200;
    margin: 0 10px;
}

@media only screen and (min-width: 480px) {
    .container { 
        font-size: 2rem;
    }
    
    .middle { 
        margin: 0 30px;
    }
    
    .bold { 
        font-weight: 500;
    }
}