@font-face { 
    font-family: 'Mulish';
    src: url('../assets/font/Mulish-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face { 
    font-family: 'Mulish';
    src: url('../assets/font/Mulish-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face { 
    font-family: 'Mulish';
    src: url('../assets/font/Mulish-Medium.woff2') format('woff2');
    font-weight: 500; 
    font-style: normal; 
}

@font-face { 
    font-family: 'Mulish';
    src: url('../assets/font/Mulish-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}


@font-face { 
    font-family: 'Mulish';
    src: url('../assets/font/Mulish-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

/* Italic Font */
@font-face { 
    font-family: 'Mulish';
    src: url('../assets/font/Mulish-LightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Mulish';
    src: url('../assets/font/Mulish-Italic.woff2') format('woff2');
    font-weight: 300; 
    font-style: italic; 
}


* {
    margin: 0; 
    padding: 0; 
    box-sizing: border-box;
}

body { 
    background-color: #F6E6FF;
    font-family: 'Mulish';
    font-weight: 300; 
    font-size: 20px;
    color: #2F2441;
}

hr { 
    width: 90%;
    border: none;
    height: 1px !important;
    background-color: #2F2441; 
}

.page { 
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: center; 
}

.text-container { 
    text-align: center; 
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8;

    padding: 35px;
}

.text-box {
    font-weight: 300;
    line-height: 2.6; 
}

.button {
    border: 1px solid #2F2441; 
    border-radius: 3px;

    padding: 8px 15px;

    color: inherit;
    text-decoration: none; 
    text-align: center; 
    font-size: 0.9rem;
}


/** 
    image container properties 
**/

.image-container { 
    display: flex;
    flex-direction: column;
    justify-content: center; 
    width: 95%; 
    padding: 20px; 
}

.image-row { 
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.image-cell { 
    width: calc(50% - 6px);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.image-cell-mobile {
    width: 100%;
}

.image-cell-desktop {
    display: none; 
}

.image-cell img {
    width: 100%;
    height: auto;
    object-fit: contain; 
    border-radius: 3px;
}

.equal-height .image-cell {
    height: auto;
}

.equal-height img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/**
    Page transition
**/

.page-transition-enter {
    opacity: 0.01;
}

.page-transition-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
}

.page-transition-exit {
    opacity: 1;
}

.page-transition-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in-out;
}



.desktop-padding {
    display: none; 
}


@media (hover: hover) {
    .button { 
        transition: border-color 0.4s ease, background-color 0.4s ease, box-shadow 0.4s ease;  
    }

    .button:hover {
        background-color: #F1D8FF;
        border-color: #F0D7E3; 
        box-shadow: 2px 2px 0px #00334f3f;
    }
}


@media only screen and (min-width: 850px){
    hr { 
        width: 100%;
    }
    
    .page-container { 
        display: flex;
    }
    
    .page {
        flex: 2;
    }

    .desktop-padding { 
        display: flex;
        flex: 1; 
        height: 100%; 
    }

    .image-container { 
        width: 80%; 
        display: flex; 
        justify-content: space-between; 
    }

    .image-cell { 
        width: calc(33% - 6px);
    }

    .image-cell-mobile { 
        display: none;
    }

    .image-cell-desktop { 
        display: inline-block;
    }
}

@media only screen and (orientation:landscape){
    .image-container { 
        width: 80%; 
        display: flex; 
        justify-content: space-between; 
    }

    .image-cell { 
        width: calc(33% - 6px);
    }

    .image-cell-mobile { 
        display: none;
    }

    .image-cell-desktop { 
        display: inline-block;
    }
}