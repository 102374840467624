.find-sub-container { 
    text-align: center;
    font-weight: 600; 
    font-size: 1rem; 
    line-height: 1.8; 

    padding: 0px 30px;
}

.find-sub-container p { 
    margin: 20px 0; 
}

.find-sub-container p:first-of-type { 
    margin-top: 0; 
}

.find-sub-container p:last-of-type {
    margin-bottom: 0; 
}

.locate-container { 
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
    
    width: 100%;
    padding: 20px;  
}

.enquiry-container { 
    display: flex; 
    flex: 1; 
    
    height: auto;

    font-size: .8rem; 
    font-weight: 500; 
    color: inherit; 

    background-color: #F1D8FF;
    border-radius: 5px; 

    padding: 20px; 
}

.map-container { 
    display: flex; 
    flex: 1;

    margin-top: 10px; 
}

.map-box { 
    width: 100%;
    height: 500px;
}


@media only screen and (min-width: 850px) {
    .find-sub-container {    
        font-size: 1.2rem; 
        line-height: 3.1; 
    }

    .find-sub-container p { 
        margin: 5px 0; 
    }
}


@media only screen and (min-width: 1000px) {
    .locate-container {
        flex-direction: row; 
        height: 500px; 
    }

    .map-container,
    .enquiry-container { 
        flex: 1; 
    }

    .map-container { 
        margin-top: 0; 
    }

    .map-box { 
        height: inherit; 
    }

}

@media only screen and (min-width: 1000px) and (min-height: 900px) {
    .locate-container {
        height: 500px; 
    }
}