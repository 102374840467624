.alert {
    border-radius: 5px;
    padding: 6px; 
    font-size: 1rem; 
    padding: 20px 40px; 
}

.success { 
    background-color: #abe49f;
    color: #1c750c;
}

.error { 
    background-color: #e796a4;
    color: #851528;
}

.closebtn { 
    font-size: 1.5rem;
    font-weight: 900; 
    color: #ffffff;

    cursor: pointer; 

    margin-left: 15px; 
}

.hide {
    display: none;
}


@media only screen and (min-width: 490px) {
    .alert {
        font-size: 1.2rem;
    }
    
    .closebtn { 
        font-size: 1.7rem;
        font-weight: 900; 
        color: #ffffff;

        cursor: pointer; 

        margin-left: 20px; 
    }

}