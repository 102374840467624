.container { 
    flex: 3; 
    padding: 20px; 
}

.carousel {
    display: flex;
    justify-content: center;
    position: relative;
    width: 90%;
    height: 600px;
    margin: 0 auto;
    overflow: hidden;
}

.carouselSlides {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.carouselSlide {
    display: flex; 
    justify-content: center;
    position: absolute;
    height: 95%;
    border-radius: 3px;
    opacity: 0; 
    transition: opacity 0.5s ease-in-out;
}

.active { 
    position: center;
    opacity: 1;
}

.carouselArrow {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: #f1d8ff;
    border: none;
    font-size: 2rem;
    padding: 0.5rem;
    border-radius: 3px;
    bottom:20px;
    cursor: pointer;
    z-index: 10;
    transition: background-color 0.3s ease;
}

.left {
    right:20px;    
    position: absolute;
    z-index: 200;
    transition:all 0.5s ease-in-out;
}

.right {
    left:20px;
    position:absolute;
    z-index: 200;
    transition:all 0.5s ease-in-out;
}

.carouselArrow:hover {
    color: rgb(47, 36, 65, 0.5);
    transform: scale(1.1);
}

.subheader {
    padding: 10px; 
    text-align: center;
}

.p { 
    font-size: 1.2rem;
    font-weight: 400;
}

@media only screen and (max-width: 1400px){
    .carousel {
        height: 500px;
    }
}

@media only screen and (max-width: 1200px){
    .carousel {
        height: 400px;
    }
}

@media only screen and (max-width: 1000px){
    .carousel {
        width: 100%; 
        height: 300px;
    }
}


@media only screen and (max-width: 1000px) and (orientation:portrait) {
    .carousel {
        width: 100%; 
        height: 400px;
    }

    .left { 
        right: 0px; 
    }
    .right { 
        left: 0px; 
    }
}