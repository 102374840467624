.giving-sub-container { 
    text-align: center;
    font-weight: 600; 
    font-size: 1.3rem; 
    line-height: 1.8; 

    padding: 5px;
    margin: 0 23px; 
}

.text-box-giving {
    font-weight: 300;
    text-align: justify;
    margin: 10px 8px;
    font-size: 1.1rem; 
}

.text-box-giving p {
    line-height: 1.7;
    margin-bottom: 25px; 
}


/**
**  Properties for charity quote section 
**/
.donation-image-container { 
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.donation-image-container img { 
    width: 100%;
    object-fit: contain;
}

.text-box-blockquote {
    background-color: #9adacc3b;
    border-radius: 10px;
    position: relative;
    padding: 25px;

    font-weight: 300;
    font-size: 0.9rem; 
    text-align: justify;

    padding-bottom: 1px;
}

.text-box-blockquote p{
    line-height: 1.7;
    margin-bottom: 25px; 
    padding-left: 15px; 
    padding-right: 15px;     
}

blockquote::before {
    content: '“';
    font-size: 70px;
    color: #bb9aca;
    position: absolute;
    top: -20px;
    left: 10px;
}

blockquote::after {
    content: '”';
    font-size: 70px;
    color: #bb9aca;
    position: absolute;
    bottom: -50px;
    right: 10px;
}



.learn-more-container { 
    padding: 15px 0px; 
}

.learn-more { 
    display: flex; 
    justify-content: center; 

    font-weight: 300;
    font-size: 1.1rem; 
    
    margin: 15px 0px; 
}



@media only screen and (min-width: 600px) {
    .text-box-giving {
        font-size: 1.1rem;
        margin: 15px 25px;
    }

    .text-box-giving p,
    .text-box-blockquote p { 
        line-height: 1.9;
    }

    .text-box-blockquote { 
        font-size: 1.0rem;
        margin: 0px 20px; 
    }
}