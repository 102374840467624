.sub-header { 
    width: 100vw;
    height: auto; 
    background-color: #F1D8FF;
    padding: 30px 0; 
}

.sub-container {
    display: flex;
    justify-content: center;
    width: 100%; 
    height: 100%; 

    object-fit: contain;  
    font-size: 1.6rem; 
    font-weight: 600;
}
