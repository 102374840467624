.enquiry-form { 
    width: 100%;
    padding: 0 4px; 

    color: #513063;
}

.group { 
    display: flex;
    flex-direction: column;
}

.label { 
    margin: 6px 0; 
}

.control { 
    width: 100%;
    margin-bottom: 3px; 
    padding: 10px; 

    border: none; 
    border-radius: 3px; 

    font: inherit;
    color: #260044; 
}

.message-box { 
    resize: none;

    line-height: 1.7; 
}

.button-container { 
    display: flex; 
    justify-content: flex-end;
}

.submit-btn { 
    margin-top: 10px; 
    padding: 6px 10px;  

    border: none; 
    border-radius: 8px; 

    font: inherit; 
    font-weight: 600; 
    color: inherit; 

    cursor: pointer; 

    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
}

.resultContainer { 
    position: fixed; 
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999; 
    max-width: 100vw; 
}


/**Bootstrap CSS Spinner Properties **/ 
.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow 0.75s linear infinite;
            animation: spinner-grow 0.75s linear infinite;
  }
  
  .spinner-grow-sm {
    width: 1rem;
    height: 1rem;
  }
  
  @-webkit-keyframes spinner-grow {
    0% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
    50% { opacity: 1; }
    100% { -webkit-transform: scale(1); transform: scale(1); opacity: 0; }
  }
  
  @keyframes spinner-grow {
    0% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
    50% { opacity: 1; }
    100% { -webkit-transform: scale(1); transform: scale(1); opacity: 0; }
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }


/** Alert Transitions **/
.transition-enter {
    opacity: 0.01;
    transform: translate(0,-50px);
}

.transition-enter-active {
    opacity: 1;
    transform: translate(0,0);
    transition: all 300ms ease-in;
}

.transition-exit {
    opacity: 1;
    transform: translate(0,0);
}

.transition-exit-active {
    opacity: 0.01;
    transform: translate(0,50px);
    transition: all 300ms ease-in;
}


@media (hover: hover) {
    .submit-btn { 
        transition: border-color 0.4s ease, background-color 0.4s ease, box-shadow 0.4s ease;  
    }

    .submit-btn:hover {
        background-color: #dfdede;
        border-color: #F0D7E3; 
        box-shadow: 1px 1px 0px #00334f3f;
    }
}

@media only screen and (min-width: 600px) {
    .group { 
        display: flex;
        flex-direction: row; 
        margin: 15px 0; 
    }

    .group:last-of-type { 
        margin: 0px; 
    }

    .label { 
        display: flex; 
        flex: 1; 

        font-size: 1rem; 
    }

    .control { 
        display: flex; 
        flex: 2; 

        font-size: 0.8rem; 

        margin-left: 8px; 
    }

    .submit-btn { 
        font-size: 0.9rem;
        padding: 10px 30px;
    }
}

@media only screen and (min-width: 850px) {
    .control { 
        font-size: 0.95rem; 
    }
}


@media only screen and (min-width: 2000px) {
    .group { 
        margin: 20px 10px; 
    }

    .group:last-of-type { 
        margin: 0px; 
    }

    .submit-btn { 
        margin-top: 0px; 
    }
}