.nav-container { 
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: center; 
}

.nav-tile { 
    flex: 1; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-img {
    max-width: 100%; 
    object-fit: cover; 
}

.nav-bar {
    list-style-type: none;
}

.nav-bar-link:link {
    text-decoration: none;
}

.nav-bar-link {
    transition: background-color 0.6s ease;
}

.nav-text { 
    color: inherit;
    text-align: center;
    font-size: 1rem;
    font-weight: 200; 
    padding: 5px; 
}

.nav-bar-link:visited {
    color: inherit; 
}


@media only screen and (min-width: 600px) {
    .nav-container {
        height: 25%;
    }

    .nav-bar-link { 
        width: 25%;
    }

    .nav-tile { 
        height: 25vh;
    }

    .nav-img {
        height: 100%;
    }

    .nav-text { 
        font-size: 1.3rem; 
        padding: 7px; 
    }
}