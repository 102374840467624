.footer-container {
    font-size: .8rem;
    line-height: 2;
    text-align: center;

    padding: 20px; 
    margin-bottom: 10px; 
}

.footer-links { 
    margin: 15px 0 30px 0; 
}

.footer-links ul li { 
    list-style-type: none; 
}

.footer-links ul li a {
    color: inherit;
    list-style-type: none; 
    text-decoration: none; 
}

.footer-links ul li:hover {
    color: #513063;
    font-style: italic;  
}

.copyright { 
    font-size: 0.6rem; 
}

.image-container { 
    width: 100vw;
    height: 6vh;
    overflow: hidden;
}

.footer-image { 
    width: 100%; 
    height: 100%;
    object-fit: cover; 
}

@media only screen and (min-width:600px) {
    .image-container { 
        height: auto; 
    }
    
    .footer-image {
        display: block; 
    }

    .footer-links {
        margin-bottom: 20px; 
    }

    .footer-links ul {
        display: flex; 
    }

    .footer-links ul li { 
        margin: 0 15px; 
        font-size: 0.8rem; 
    }

    .footer-links ul li:last-child {
        border-left: 1px solid #2F2441; 
        padding-left: 20px; 
    }
}