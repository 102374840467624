.logo-container { 
    width: 100%;
    height: auto; 
    display: flex; 
    justify-content: center;
}

.logo-container img {
    max-width: 100%; 
    padding: 30px; 
}

@media only screen and (min-width: 490px) {
    .logo-container { 
        height: 200px; 
    }

    .logo-container img { 
        min-height: 100%; 
    }
}