.text-box-story {
    text-align: justify;
    font-size: .9rem; 
    font-weight: 300;

    padding: 15px; 
}

.text-box-story p { 
    line-height: 1.7;
    margin-bottom: 25px; 
}

.portrait img {
    max-width: 100%; 
    opacity: .8; 
    border-radius: 50%;

    padding: 5px; 

    object-fit: contain;
}


@media only screen and (min-width: 480px) {
    .text-box-story { 
        font-size: 1.1rem; 
    }
}